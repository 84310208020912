import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ContainerFluid from '../ContainerFluid'
import styles from './News.module.scss'
import NewsCard from './NewsCard'
import homeContent from '../../i18n/home'
import Loading from 'component/Loading'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const NewsList = ({ newsList, locale }) => {
    const renderNews = ({ data, total }) => {
        return data.map(item => (
            <SwiperSlide style={{height: "375px"}} key={"slide-" + item.id}>
                <NewsCard
                    id={item.id}
                    slug={item.slug?.substring(0, item.slug.length - 9)}
                    title={item.title}
                    desc={item.short_content}
                    created_at={item.created_at}
                    src={
                        item?.images?.image 
                        ? process.env.NEXT_PUBLIC_MEDIA_FILE + item.images.image 
                        : "/static/news_thumb.png"
                    }
                    // src={`/static/${item?.images?.image}`}
                    locale={locale}
                    objectFit="scale-down"
                />
            </SwiperSlide>
        ))
    }
    return (
        <ContainerFluid
            className="home-news-fluid"
        >
            <div className={classNames("container", styles.news)}>
                <h2>{homeContent.news.title[locale]}</h2>
                {
                    !newsList
                        ? <div><Loading /></div>
                        : newsList.error
                            ? (
                                <p>
                                    {locale === "vi" ? "Lỗi kết nối hệ thống" : "Failed to load article"}
                                </p>
                            )
                            : newsList?.data?.length > 0
                                ?
                                <div className="row">
                                    <div className="col-12">
                                        <div className="news-slider-wrapper w-100" style={{padding: "0 44px"}}>
                                            <div className="swiper-button-next outside-wrapper"><ChevronRight style={{ fontSize: "30px" }} /></div>
                                            <Swiper
                                                initialSlide={0}
                                                spaceBetween={30}
                                                slidesPerView={3}
                                                slidesPerGroup={1}
                                                navigation={{
                                                    nextEl: ".swiper-button-next",
                                                    prevEl: ".swiper-button-prev",
                                                }}
                                                pagination={{ clickable: true }}
                                                // scrollbar={{ draggable: true }}
                                                speed={400}
                                                breakpoints={{
                                                    320: {
                                                        slidesPerView: 1,
                                                        slidesPerGroup: 1
                                                    },
                                                    568: {
                                                        slidesPerView: 2,
                                                        slidesPerGroup: 2
                                                    },
                                                    960: {
                                                        slidesPerView: 3,
                                                        slidesPerGroup: 3
                                                    },
                                                    1366: {
                                                        slidesPerView: 3,
                                                        slidesPerGroup: 3
                                                    }
                                                }}
                                            >
                                                {renderNews(newsList)}
                                            </Swiper>
                                            <div className="swiper-button-prev outside-wrapper"><ChevronLeft style={{ fontSize: "30px" }} /></div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="bg-white w-100 p-5 text-center">
                                    {locale === "vi" ? "Chưa có bài viết" : "No articles now"}
                                </div>
                }
            </div>
        </ContainerFluid>
    );
};

export default NewsList;