import React from 'react';
import styles from './Service.module.css'
import Link from 'next/link'
import classNames from 'classnames';
import Image from './ImgComponent';
import {ChevronRight} from 'react-bootstrap-icons'
import {useRouter} from 'next/router'
import Lottie from 'react-lottie';
import HtmlRender from './HtmlRender'

const ServiceCard = ({id, title, description, src, lottieFile, customSize = false, className}) => {
  const {locale} = useRouter()
  return (
    <div className={classNames("col-12 col-md-6 col-lg-4 align-items-stretch", styles.serviceCol)}>
      <div className={classNames("d-flex flex-column justify-content-start", styles.serviceItems)}>
        <div className={classNames("d-flex flex-column", styles.serviceTitle)}>
          <h3 className="mt-auto" dangerouslySetInnerHTML={{ __html: title }}>
            {/* {title} */}
          </h3>
        </div>
        <hr style={{ color: "#707070", margin: "10px 0" }} />
        <HtmlRender className={classNames(styles.description, styles[className])} dom="p">
            {description}
        </HtmlRender>
        
        <div className="my-auto">
          {
            lottieFile && 
            <div className="lottie-wrapper">
              <div className="lottie-bg">
              </div>
              <Lottie 
                options={{
                  loop: true,
                  autoplay: true, 
                  animationData: lottieFile.component || undefined,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
                width={"100%"}
                height={customSize ? lottieFile.size[1] : undefined}

                // width={lottieFile.size[0]}
                // height={lottieFile.size[1]}

                // width={calcDimension(ratio)?.[0]}
                // height={calcDimension(ratio)?.[1]}
              />
            </div>
          }
        </div>
        <Link href={`/services/${id}`} passHref>
          <a className={
            classNames(
              styles.serviceButton,
              `
              d-flex
              align-items-center
              justify-content-between
              w-100
              text-left
              custom
            `)
          }>
            <span>{locale === "vi" ? "Xem chi tiết" : "View Details"}</span>
            <ChevronRight style={{ marginRight: "-5px" }} />
          </a>
        </Link>
      </div>
    </div>
  );
};

export default ServiceCard;